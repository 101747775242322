<template>
  <div class="row auth-row">
    <div class="col-12 col-lg-6">
      <h1 class="mb-4 ms-4 color-primary">Welcome back</h1>
      <div class="auth-img">
        <app-image src="batters-info.png" alt="Batters Lifecycle" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <app-form
        :form="form"
        :model="model"
        :rules="rules"
        button="Login"
        @submitted="handleSubmit"
      />
      <p class="mt-4 fs-16">
        No account yet?
        <span class="inline-link" @click="$emit('showLogin', false)"
          >Create an account</span
        >
      </p>
      <p class="mt-2 fs-16">
        Forgot password?
        <span class="inline-link" @click="resetPassword">Reset password</span>
      </p>
    </div>
  </div>
</template>

<script>
import { form, model, rules } from '@/utils/config/login';
import Database from '@/utils/services/Database';

import { ElMessageBox } from 'element-plus';

export default {
  name: 'AuthLogin',
  emits: ['showLogin'],
  data() {
    return {
      form: [],
      model: {},
      rules: {},
    };
  },
  created() {
    this.form = JSON.parse(JSON.stringify(form));
    this.model = JSON.parse(JSON.stringify(model));
    this.rules = JSON.parse(JSON.stringify(rules));
  },
  methods: {
    async handleSubmit(data) {
      const status = await this.$store.dispatch('login', {
        email: data.email,
        password: data.password,
        remember_me: data.remember_me,
      });

      if (status === 200) this.$router.push({ name: 'UserDashboard' });
    },
    resetPassword() {
      ElMessageBox.prompt('Please input your email', 'Reset password', {
        confirmButtonText: 'Reset',
        cancelButtonText: 'Cancel',
        inputPattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        inputErrorMessage: 'Invalid Email',
      })
        .then(({ value }) => {
          if (value) {
            this.reset(value);
          }
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'An error occurred while resetting your password',
            offset: 200,
          });
        });
    },
    async reset(email) {
      const status = await Database.resetPassword(email);

      if (status === 200)
        return this.$message({
          type: 'success',
          message: 'An email has been sent to you to reset your password',
          offset: 200,
        });

      return this.$message({
        type: 'error',
        message: 'An error occurred while resetting your password',
        offset: 200,
      });
    },
  },
};
</script>
