const model = {
  email: '',
  password: '',
  remember_me: false,
};

const form = [
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'Your email address',
    required: true,
    component: 'el-input',
  },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    placeholder: 'Your password',
    required: true,
    component: 'el-input',
    is_password: true,
  },
  {
    name: 'remember_me',
    label: 'Remember me',
    type: 'checkbox',
    component: 'el-checkbox',
    required: false,
  },
];

const rules = {
  email: [
    {
      type: 'required',
      message: 'Please enter your email address',
    },
    {
      type: 'email',
      message: 'Please enter a valid email address',
    },
  ],
  password: [
    {
      type: 'required',
      message: 'Please enter your password',
    },
  ],
  remember_me: []
};

export { model, form, rules };
